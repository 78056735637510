class ZIDXAccountUsers implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountUsersContainer";
    }
    getMatchURL(){
        return "/account/users";
    }
    render(){
        let listView=new ZIDXAccountListView();
        listView.render();
    }
}